<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { nonEmpty } from '@/utils/templates'
import { TDIA } from '@/utils/consts'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      const horaDesde = this.$options.filters.shortTime(item.horadesde)
      const horaHasta = this.$options.filters.shortTime(item.horahasta)
      item.title = this.$options.filters.linebreaksBr(nonEmpty`
        ${item.descripcion} ${horaDesde} a ${horaHasta}`)
      item.subtitle = this.$options.filters.linebreaksBr(nonEmpty`
        ${item.tturno_descripcion} (${item.alias})`)
      item.avatar = TDIA.tipos_dia[item.idtdia.toString() - 1].alias
      item.avatarColor = TDIA.tipos_dia[item.idtdia.toString() - 1].color
      return item
    }
  }
}
</script>
